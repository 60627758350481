import React, { useEffect } from "react"
import axios from "axios"

import WithLocation from "../components/withLocation"

import Layout from "../components/layout"

const ConfirmPage = ({ search, navigate }) => {
  const confirmUrl = "/.netlify/functions/confirmRsvp"

  useEffect(() => {
    // In case someone tries to navigate directly here
    if (!search.email) {
      navigate("/404")
    } else {
      // FIXME: Remove this setTimeout for prod
      setTimeout(() => {
        axios
          .post(confirmUrl, search)
          .then(() => {
            console.log("success")
            navigate("/rsvp")
          })
          .catch(() => navigate("/guest-not-found"))
      }, 3000)
    }
  }, [])
  return (
    <Layout>
      <h1>Hang tight</h1>
      <p>We’re checking your RSVP.</p>
    </Layout>
  )
}

export default WithLocation(ConfirmPage)
